@import 'styles/includes';

.SideBySide {
    $root: &;

    &__Container {
        @extend %container;
        margin: 4rem auto;

        @include media(xl) {
            margin: 10rem auto;
        }
    }
    &__List {
        display: grid;
        gap: 1rem;

        @include media(xl) {
            gap: 2rem;
        }
    }

    &__Item {
        position: relative;
    }

    &__Link {
        @extend %card-link;
        z-index: 2;
    }

    &__Title {
        padding: 2rem;
        font-family: $font-family-bold;
        font-size: 3rem;
        font-style: normal;
        font-weight: $font-weight-bold;
        line-height: 100%;
        text-align: center;
        border: 0.1rem solid $black;
        border-bottom: 0;

        transition:
            background-color $transition-ease-out-expo,
            color $transition-ease-out-expo;

        @media (hover: hover) {
            #{$root}__Item:hover & {
                background-color: $black;
                color: $white;
            }
        }

        @include media(l) {
            display: none;
        }
    }

    &__Grid {
        border: 0.1rem solid $black;
        display: grid;
        grid-template-columns: repeat(2, 1fr);
    }

    &__Content {
        width: 100%;
        position: relative;
        aspect-ratio: 185/340;
        background-color: $black;
        overflow: hidden;

        @include media(xl) {
            aspect-ratio: 640/750;
        }

        &:last-child {
            border-left: 0.1rem solid $black;
        }
    }

    &__Video {
        height: 100%;
        aspect-ratio: 9/16;
        position: relative;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);

        @include media(xl) {
            height: auto;
        }

        iframe {
            width: 100%;
            height: 100%;
            position: absolute;
            inset: 0;
        }
    }

    &__ImageTitle {
        display: none;

        @include media(l) {
            padding: 2rem;
            font-family: $font-family-bold;
            font-size: 6rem;
            font-style: normal;
            font-weight: $font-weight-bold;
            line-height: 100%;
            text-align: center;
            color: $white;
            display: flex;
            justify-content: center;
            align-items: center;
            position: absolute;
            inset: 0;
            z-index: 1;
        }
    }

    &__Bottom {
        padding: 2rem;
        border: 0.1rem solid $black;
        border-top: 0;
        display: grid;
        gap: 1rem;
        transition:
            background-color $transition-ease-out-expo,
            color $transition-ease-out-expo;
        color: $black;

        @media (hover: hover) {
            #{$root}__Item:hover & {
                background-color: $black;
                color: $white;
            }
        }

        @include media(l) {
            padding: 4rem;
            grid-template-columns: 1fr auto;
            align-items: center;
        }
    }

    &__Text {
        font-family: $font-family-bold;
        font-size: 1.2rem;
        font-weight: $font-weight-bold;
        line-height: 130%;
        white-space: pre-wrap;

        @include media(l) {
            font-size: 1.6rem;
        }
    }

    &__Icon {
        margin-left: auto;
        display: inline-block;
        width: 1.5rem;
        height: 1.5rem;
        position: relative;
        overflow: hidden;

        @include media(l) {
            width: 2rem;
            height: 2rem;
        }

        span {
            position: absolute;
            top: 0;
            left: 0;
            width: 1.5rem;
            height: 1.5rem;

            @include media(l) {
                width: 2rem;
                height: 2rem;
            }

            @media (hover: hover) {
                #{$root}__Item:hover & {
                    animation: arrow-loop 1s ease-in-out;
                }
            }
        }
    }
}

@keyframes arrow-loop {
    10% {
        left: 0;
        opacity: 1;
    }

    30% {
        left: 2rem;
        opacity: 1;
    }

    31% {
        opacity: 0;
        left: -2rem;
    }

    32% {
        left: -2rem;
        opacity: 1;
    }

    100% {
        opacity: 1;
        left: 0;
    }
}
